import DropDown from '../../elements/landingDropDown'
import { landingSort , landingTime } from '../../../data/dropDown'
export default function landingTopHeader() {
    return (
        <div className="landing-top-header">
            <span className="landing-top-header__txt">TOP</span>
            <DropDown items={landingSort}/>
            <span className="landing-top-header__txt">IN</span>
            <DropDown items={landingTime}/>
        </div>
    )
}
