export const nav = ['Collectibles', 'Gaming', 'Digital Art', 'No Filter']

export const sliderCards = [
    {
        name : 'Psycho Astronaut',
        id : '@Emilie', 
        likes : '25',
        price : '1.45',
        convert : '3,840.25',
        img : './images/landing-card.png'
    },
    {
        name : 'Psycho Astronaut',
        id : '@Emilie', 
        likes : '25',
        price : '1.45',
        convert : '3,840.25',
        img : './images/card2.png'
    },
    {
        name : 'Psycho Astronaut',
        id : '@Emilie', 
        likes : '25',
        price : '1.45',
        convert : '3,840.25',
        img : './images/card3.png'
    },
    {
        name : 'Psycho Astronaut',
        id : '@Emilie', 
        likes : '25',
        price : '1.45',
        convert : '3,840.25',
        img : './images/landing-card.png'
    },
    {
        name : 'Psycho Astronaut',
        id : '@Emilie', 
        likes : '25',
        price : '1.45',
        convert : '3,840.25',
        img : './images/card3.png'
    },
]