import { _LOGO } from '../../../assets/icons/icons'
import Input from '../../fields/landingInput'
import Btn from '../../elements/landingBtn'
import Links from './landingFooterLink'
import { nyftis, community } from '../../../constants/routes/landingRoutes'
import useResponsive from '../../../hooks/useResponsive'
import { useSelector } from 'react-redux'

export default function LandingFooter() {
    const [DESKTOP, LANDSCAPE, TABLET, MOBILE] = useResponsive()
    const theme = useSelector(state => state.theme)
    return (
        <div className="landing-footer">
            <div className="container landing-footer-column">
                <div className="landing-footer-left">
                    {theme === 'dark' ?
                        <img className='logo-img-txt' src="./images/logo-dark.svg" alt="" />
                        :
                        <img className='logo-img-txt' src="./images/logo-light.svg" alt="" />
                    }
                    {(!TABLET) ?
                        <>
                            <span className="landing-footer-left__txt">
                                Subscribe for the latest Nyftis news, drops & collectibles
                            </span>
                            <div className="landing-footer-form">
                                <Input placeholder="Your Email" className="landing-footer-left__input" />
                                <Btn className="btn__solid landing-footer-left__btn"
                                    title="Subscribe" />
                            </div>
                        </> : ''}
                </div>
                <div className="landing-footer-links">
                    <Links title="Nyftis" items={nyftis} />
                    <Links title="Community" items={community} />
                </div>
                {TABLET &&
                    <>
                        <div className="divider"></div>
                        <div className="landing-mobile-subscribe">
                            <span className="landing-footer-left__txt">
                                Subscribe for the latest Nyftis news, drops & collectibles
                            </span>
                            <Btn className="btn__solid landing-footer-left__btn"
                                title="Subscribe" />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
