import React from 'react'

export default function TwinSpan({ main = '1M', title = 'Title' }) {
    return (
        <span className="twin-span">
            {main}
            <span className="twin-span__title">
                {title}
            </span>
        </span>
    )
}
