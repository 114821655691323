import Header from '../pages/landing/landingCardHeader'
import Profile from '../pages/landing/landingCardProfile'
import Footer from '../pages/landing/landingCardFooter'
import { useEffect, useState } from 'react'
import useResponsive from '../../hooks/useResponsive';


const LandingCard = ({ className = 'large', img, name, likes, id, price, convert, type = 'bid', landing = true, effect = false }) => {

    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const [scale, setScale] = useState(1);
    const [DESKTOP] = useResponsive()

    useEffect(() => {
        const animationHandler = (e) => {
            setPosX((window.innerWidth - e.pageX) / 15)
            setPosY((window.innerHeight - e.pageY) / 15)
            if (e.pageY > 450 && e.pageY < 650) {
                setScale(1.05)
            }
            else if (e.pageY > 550 && e.pageY < 750) {
                setScale(1.1)
            }
            else if (e.pageY > 450 && e.pageY < 550) {
                setScale(1)
            }
            else if (e.pageY > 350 && e.pageY < 450) {
                setScale(0.95)
            }
            else if (e.pageY > 350 && e.pageY < 450) {
                setScale(0.95)
            }
            else if (e.pageY > 250 && e.pageY < 350) {
                setScale(0.9)
            }
            else if (e.pageY > 0 && e.pageY < 250) {
                setScale(0.85)
            }

        }
        if (effect && DESKTOP) {
            window.addEventListener('mousemove', animationHandler)
        }
        return () => {
            window.removeEventListener('mousemove', animationHandler)
        }
    }, [DESKTOP]);

    const transform = DESKTOP ? { transform: `translateX(${-posX}px) translateY(${-posY}px) scale(${scale})` } : {}
    return (
        <div
            style={{
                backgroundImage: `url('${img}')`,
                ...transform
            }} className={`landing-card ${className} ${landing ? 'landing' : ''}`}>
            <div>
                <Header title={name} count={likes} />
                <Profile img='./images/landing-card-profile.png' id={id} />
            </div>
            <Footer type={type} price={price} convert={convert} />
        </div>
    )
};

export default LandingCard
