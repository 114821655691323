import Header from './landingHeader'
import Main from './landingMain'
import Drops from './landingDrops'
import Tops from './landingTop'
import Category from './landingCategory'
import Footer from './landingFooter'
import ThemeBtn from '../../elements/themeBtn';

const Landing = () => {
    return (
        <>
            <ThemeBtn />
            <>
                <img src="./images/bg-line.svg" alt="" className="bg-line-up" />
                <img src="./images/bg-line1.svg" alt="" className="bg-line-down" />
            </>
            <>
                <img src="./images/bg-line-light.svg" alt="" className="bg-line-up" />
                <img src="./images/bg-line1-light.svg" alt="" className="bg-line-down" />
            </>
            <Header />
            <Main />
            <Drops />
            <Tops />
            <Category />
            <Footer />
        </>
    )
}

export default Landing

