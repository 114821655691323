import { Dropdown } from 'antd';
import { useState } from 'react';
import { _DOWN, _TICK } from '../../assets/icons/icons'
export default function LandingDropDown({ items }) {
    const [active, setActive] = useState(items[0].title)

    const handler = (title) => {
        setActive(title)
    }
    const menu = (
        <div className="drop-down-bg">
            <div className="drop-down">
                {items.map(({ title }, ind) => (
                    <a onClick={() => {
                        handler(title)
                    }} key={title} className={`drop-down__item`}><span>{title}</span>{active === title ? _TICK() : ''}</a>
                ))}
            </div>
        </div>
    );
    return (
        <div>
            <Dropdown overlay={menu} trigger={['click']}>
                <a className="drop-down__title" onClick={e => e.preventDefault()
                }>
                    <span>{active}</span>{_DOWN()}
                </a>
            </Dropdown>
        </div>
    )
}
