export default function CountDown({ days, hours, minutes, seconds, complated }) {
    return (
        <div className="count-down">
            <div className="count-down__part">
                <span className="count-down__count">
                    {days}
                </span>
                <span className="count-down__title">
                    Days
                </span>
            </div>
            <div className="count-down__part">
                <span className="count-down__count">
                {hours}
                </span>
                <span className="count-down__title">
                    Hours
                </span>
            </div>
            <div className="count-down__part">
                <span className="count-down__count">
                {minutes}
                </span>
                <span className="count-down__title">
                    Minutes
                </span>
            </div>
            <div className="count-down__part">
                <span className="count-down__count">
                {seconds}
                </span>
                <span className="count-down__title">
                    Seconds
                </span>
            </div>
        </div>
    )
}
