import { useState } from "react"
import { nav } from '../../../data/landingCategory'

export default function LandingCategoryNav() {
    const [sort, setSort] = useState(nav[0])
    const sortHandler = (select) => {
        setSort(select)
    }
    return (
        <div className="landing-category-nav">
            {nav.map((item, ind) => (
                <span onClick={() => {
                    sortHandler(item)
                }} className={`landing-category-nav__item ${item === sort ? 'active' : ''}`} key={item}>{item}</span>
            ))}
        </div>
    )
}
