import React from 'react'
import Info from './landingDropsInfo'
export default function landingDropsMain() {
    return (
        <div className="landing-drops-main">
           <img src="./images/landing-drops.png" alt="" />
           <Info owner={'HERMÈS'} date={'12th Aug'}/> 
        </div>
    )
}
