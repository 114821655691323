const statistics = [
    {
        count : '2M' ,
        title : 'Artword'
    },
    {
        count : '357K' ,
        title : 'Auction'
    },
    {
        count : '39K' ,
        title : 'Artist'
    }
];

export default statistics