import { Link } from 'react-router-dom';
import {_ARROWRIGHT} from '../../../assets/icons/icons'

export default function landingDropsHeader() {
    return (
        <div className="landing-drops-header">
            <span className="landing-drops-header__title">
                DROPS
            </span>
            <Link className="landing-drops-header__link" to="/drops">View More{_ARROWRIGHT(2)}</Link>
        </div>
    )
}
