import { Component } from 'react'
import Slider from "react-slick";
import Card from '../../elements/landingCard'
import { sliderCards } from '../../../data/landingCategory'

export default class SimpleSlider extends Component {
  state = {
    margin: 0,
    isTablet: window.innerWidth < 992
  };
  updateDimensions = () => {
    let base = document.querySelector('.landing-category-nav')
    let firstSlider = document.querySelectorAll('.sliderParent')[0]
    this.setState({ isTablet: window.innerWidth < 992, margin: base.getBoundingClientRect().left });
    firstSlider.style.marginLeft = `${base.getBoundingClientRect().left}px`
  };
  componentDidMount() {
    let base = document.querySelector('.landing-category-nav')
    let firstSlider = document.querySelectorAll('.sliderParent')[0]
    firstSlider.style.marginLeft = `${base.getBoundingClientRect().left}px`
    window.addEventListener('resize', this.updateDimensions);
    this.setState({ isTablet: window.innerWidth < 992, margin: base.getBoundingClientRect().left });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  render() {
    const settings = {
      className: "slider variable-width",
      infinite: false,
      slidesToShow: this.state.isTablet ? 1 : 3,
      slidesToScroll: 1,
      variableWidth: true,
      dots: false,
      arrows: false,
    };
    return (
      <div className=" landing-category-contain">
        <Slider {...settings}>
          {sliderCards.map(({ name, id, likes, img, convert, price }, ind) => (
            ind === 0 ? <div key={ind} className="sliderParent" style={{ width: (this.state.isTablet ? 210 : 315) + this.state.margin }}>
              <Card className="small" name={name} likes={likes} id={id} price={price} convert={convert} img={img} />
            </div> : <div key={ind} className="sliderParent" style={{ width: this.state.isTablet ? 210 : 315 }}>
              <Card className="small" name={name} likes={likes} id={id} price={price} convert={convert} img={img} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
