import { useRef, useState } from 'react'
import { _DOWN } from '../../assets/icons/icons'
import useOnClickOutside from '../../hooks/useOnClickOutside'

export default function DropDownBtn() {
    const [showModal, setShowModal] = useState(false)
    const ref = useRef();
    useOnClickOutside(ref, () => setShowModal(false));
    const selectActive = (e) => {
        setShowModal( show => !show)
    }
    return (
        <div ref={ref} className={`landing-select ${showModal ? 'active' : ''}`}
            onClick={selectActive}
        >
            <div className="landing-select__items">
                <div className="landing-select__icon">
                    {_DOWN()}
                </div>
                <div className="landing-select__item">Category</div>
                <div className="landing-select__item">Created</div>
                <div className="landing-select__item">Owned</div>
                <div className="landing-select__item">On Sale</div>
                <div className="landing-select__item">Favourite</div>
            </div>
        </div>
    )
}
