import landingRoutes from '../../../constants/routes/landingRoutes'
import LandingConnect from '../../elements/landingBtn'
import { NavLink, Link } from 'react-router-dom';
import { Fragment } from 'react';


export default function LandingNav() {
    return (
        <div className="landing-nav">
            {landingRoutes.map(({ title, path }, ind) => (
                <Fragment key={ind}>
                    {ind === 4 && <div className="landing-nav__line"></div>}
                    <NavLink className="landing-nav__link" to={path}>
                        {title}
                    </NavLink>
                </Fragment>
            ))}
            <Link className="connect-wallet-link" to='/signin'>
                <LandingConnect title='Connect wallet' className='btn__connect btn__border' />
            </Link>
        </div>
    )
}
