import React from 'react';
import Countdown from "react-countdown";
import CardCountDown from './cardCountDown';

const AuctionCardTime = React.memo(() => {
    return (
        <div className="auction-card-time">
            <span className="auction-card-time__title">
                Auction ends in
            </span>
            <Countdown date={Date.now() + 10000000} renderer={CardCountDown} />
        </div>
    )
})

export default AuctionCardTime