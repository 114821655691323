import React from 'react'
import { _SEARCH, _MENU } from '../../../assets/icons/icons'

export default function LandingHeaderMobile() {
    return (
        <div className="landing-header-mobile">
            <div className="landing-header-mobile__items">
                {_SEARCH()}
            </div>
            <div className="landing-header-mobile__items">
                {_MENU()}
            </div>
        </div>
    )
}
