import React from 'react'
import TwinSpan from '../../elements/twinSpan'
import statistics from '../../../data/landingStatistics'

export default function LandingMainStatus() {
    return (
        <div className="landing-main-status">
            {statistics.map(({count , title}, ind) => (
                <TwinSpan key={ind} main={count} title={title}/>
            ))}
        </div>
    )
}

