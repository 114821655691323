import { useState } from 'react';
import { _SUN, _MOON } from '../../assets/icons/icons';
import { changeTheme } from '../../store/actions/index';
import { useDispatch, useSelector } from 'react-redux';

export default function ThemeBtn() {
    const theme = useSelector(state => state.theme)
    return (
        <div className="landing-theme-btn">
            {theme === 'dark' ? <Night/> : <Sun/>}
        </div>
    )
}

const Night = () => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch({ type: "THEME", payload: 'light' })
    }
    return (
        <button onClick={handleClick} className="landing-theme-btn__moon landing-theme-btn__btn">
            {_MOON()}
        </button>
    )
}

const Sun = () => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch({ type: "THEME", payload: 'dark' })
    }
    return (
        <button onClick={handleClick} className="landing-theme-btn__sun landing-theme-btn__btn">
            {_SUN()}
        </button>
    )
}