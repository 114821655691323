import { createReducer } from "@reduxjs/toolkit";
import { changeTheme } from '../actions/index';

const initial = localStorage.getItem('theme') || 'dark'
export const theme = createReducer(initial, {
    [changeTheme]: (state, { payload }) => {
        if (payload === "dark") {
            document.querySelector('html').dataset.theme = 'dark'
            localStorage.setItem('theme' , 'dark')
        }
        else{
            document.querySelector('html').dataset.theme = 'light'
            localStorage.setItem('theme' , 'light') 
        }
        return payload
    }
})