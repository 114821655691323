import { Link } from "react-router-dom"


export default function LandingFooterLink({ title = '', items }) {
    return (
        <div className="landing-footer-link">
            <span className="landing-footer-link__title">{title}</span>
            {items.map(({ title, path }, ind) => (
                <Link key={ind} to={path}>{title}</Link>
            ))}

        </div>
    )
}
