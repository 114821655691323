export const landingSort = [
    {
        title : 'SELLERS'
    },
    {
        title : 'BUYERS'
    },
]

export const landingTime = [
    {
        title : '1DAY'
    },
    {
        title : '7DAYS'
    },
    {
        title : '30DAYS'
    },
] 