import Card from '../../elements/landingTopCard'
import { cards } from '../../../data/landingTop'

export default function LandingTopContain() {
    return (
        <div className="landing-contain">
            <Card items={cards}/>
        </div>
    )
}
