
export default function LandingSearch({ icon = '', placeholder = '', className = '' }) {
    const inputHandler = (el) => {
        el.target.parentElement.classList.add('active')
    }
    const inputBlur = (el) => {
        el.target.parentElement.classList.remove('active')
    }
    const inputFocus = (el) => {
        const element = el.target
        const input = element.querySelector('input')
        if (element.classList.contains('landing-search')) {
           input.focus() 
        }
    }
    return (
        <div onClick={(e) => { inputFocus(e) }} className={`landing-search ${className}`}>
            {icon}
            <input
                onFocus={(e) => inputHandler(e)}
                onBlur={(e) => inputBlur(e)}
                className="landing-search__input" type="text" placeholder={placeholder} />
        </div>
    )
}
