import { _HEART } from '../../../assets/icons/icons'

export default function landingCardHeader({ title, count }) {
    return (
        <div className="landing-card-header">
            <span className="blur-bg landing-card-header__title">{title}</span>
            <div className="blur-bg landing-card-header__like">
                <span className="landing-card-header__count">{count}</span>
                {_HEART()}
            </div>

        </div>
    )
}
