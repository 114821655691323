import Header from './landingDropsHeader'
import Main from './landingDropsMain'

export default function landingDrops() {
    return (
        <div className='container landing-drops'>
            <Header/>
            <div className="landing-drops__divider"></div>
            <Main/>
        </div>
    )
}
