import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const images = [5, 4, 3, 2, 1]

const LandingEffect = () => {
    const [posX, setPosX] = useState(0);
    const [posY, setPosY] = useState(0);
    const [scale, setScale] = useState(1);
    const theme = useSelector(state => state.theme)
    useEffect(() => {
        const animationHandler = (e) => {
            setPosX((window.innerWidth - e.pageX) / 15)
            setPosY((window.innerHeight - e.pageY) / 15) 
            if (e.pageY > 450 && e.pageY < 650) {
                setScale(1.05)
            }
            else if (e.pageY > 550 && e.pageY < 750) {
                setScale(1.1)
            }
            else if (e.pageY > 450 && e.pageY < 550) {
                setScale(1)
            }
            else if (e.pageY > 350 && e.pageY < 450) {
                setScale(0.95)
            }
            else if (e.pageY > 350 && e.pageY < 450) {
                setScale(0.95)
            }
            else if (e.pageY > 250 && e.pageY < 350) {
                setScale(0.9)
            }
            else if (e.pageY > 0 && e.pageY < 250) {
                setScale(0.85)
            }
        }
        window.addEventListener('mousemove', animationHandler)
        return () => {
            window.removeEventListener('mousemove', animationHandler)
        };
    }, []);

    return (
        <>
            {images.map((item) => (
                <img
                key={item}
                style={{ transform: `translateX(${-posX}px) translateY(${-posY}px) scale(${scale})` }} 
                className='landing-card-effects' 
                src={`./images/card-effect/${theme}/${item}.png `} 
                alt="" />
            ))}
        </>
    )
};

export default LandingEffect