import { _LOGO, _SEARCH, _LOGO_IMG, _LOGO_TXT } from '../../../assets/icons/icons'
import LandingInput from '../../fields/landingInput'
import LandingNav from './landingNav'
import useResponsive from '../../../hooks/useResponsive'
import MobileItems from './landingHeaderMobile'
import { useSelector } from 'react-redux'


export default function LandingHeader() {
    const [DESKTOP] = useResponsive()
    const theme = useSelector(state => state.theme)
    return (
        <div className="landing-container landing-header">
            {DESKTOP ?
                <>
                    {theme === 'dark' ?
                        <img src="./images/logo-dark.svg" alt="" />
                        :
                        <img src="./images/logo-light.svg" alt="" />
                    }
                    <LandingInput className="landing-header-search__desk" icon={_SEARCH('landing-search__icon')} placeholder="Search by creator, collectible or collection" />
                    <LandingNav />
                </>
                :
                <>
                    {theme === 'dark' ?
                        <>
                            <img src="./images/logo-img-dark.svg" alt="" />
                            <img src="./images/logo-txt-dark.svg" alt="" />
                        </>
                        :
                        <>
                            <img src="./images/logo-img-light.svg" alt="" />
                            <img src="./images/logo-txt-light.svg" alt="" />
                        </>
                    }

                    <MobileItems />
                </>}
        </div>
    )
}
