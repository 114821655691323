import Price from './landingCardPrice'
import AuctionCardTime from '../../elements/auctionCardTime';

export default function landingFooterCard({ price, convert, type = 'bid' }) {
    return (
        <div className='landing-card-footer blur-bg' >
            {type === 'bid' ?
                <>
                    <Price price={price} convert={convert} />
                    <div className="landing-card-footer__divider"></div>
                    <AuctionCardTime/>
                </> :
                <>
                    <Price type='fixed' price={price} convert={convert} />
                </>
            }
        </div>
    )
}
