export const cards = [
    {
        name : 'Emilie Butler',
        price: '10.5',
        id : '@Emilie',
        convert : '10,535.95',
        img : '../images/prof1.png'
    },
    {
        name : 'Emilie Butler',
        price: '10.5',
        id : '@Emilie',
        convert : '10,535.95',
        img : '../images/prof2.png'
    },
    {
        name : 'Emilie Butler',
        price: '10.5',
        id : '@Emilie',
        convert : '10,535.95',
        img : '../images/prof3.png'
    },
    {
        name : 'Emilie Butler',
        price: '10.5',
        id : '@Emilie',
        convert : '10,535.95',
        img : '../images/prof4.png'
    },
    {
        name : 'Emilie Butler',
        price: '10.5',
        id : '@Emilie',
        convert : '10,535.95',
        img : '../images/prof5.png'
    },
    {
        name : 'Emilie Butler',
        price: '10.5',
        id : '@Emilie',
        convert : '10,535.95',
        img : '../images/prof6.png'
    },
];