import { Routes, Route } from 'react-router-dom';
import Landing from "./components/pages/landing/landing";
import 'antd/dist/antd.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/sass/index.scss';
import { useEffect } from 'react';


const App = function App() {
  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (!theme) {
      localStorage.setItem('theme' , 'dark')
    }
    else{
      document.querySelector('html').dataset.theme = theme
    }
    document.querySelector('body').style.transition = 'background 0.5s linear'
  }, [])
  return (
    <Routes>
      <Route path="*" element={<Landing />} />
    </Routes>
  )
};

export default App;
