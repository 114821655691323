import landingMainTxt from '../../../data/landingMainTxt'

export default function LandingMainTitles() {
    return (
        <div className="landing-main-titles">
            <span className="landing-main-titles__header">
                {landingMainTxt.title}
            </span>
            <span className="landing-main-titles__body first">
                {landingMainTxt.body}
            </span>
            <span className="landing-main-titles__body">
                NFTs
            </span>
            <span className="landing-main-titles__footer">
                {landingMainTxt.footer}
            </span>
        </div>
    )
}
