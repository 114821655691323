import React from 'react'
import Btn from '../../elements/landingBtn'
import {_ARROWRIGHT} from '../../../assets/icons/icons'

export default function LandingMainBtns() {
    return (
        <div className='landing-main-btns'>
            <Btn title="Explore Now" icon={_ARROWRIGHT(1)} className="btn__solid btn__explore"/>
            <Btn  title="Create NFT!" className="btn__border btn__create"/>
        </div>
    )
}
