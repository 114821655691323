import Btn from '../../elements/landingBtn'
import { _ARROWRIGHT, _NOTIF } from '../../../assets/icons/icons'
import CountDown from '../../elements/countDown';
import Countdown from "react-countdown";
export default function landingDropsInfo({ owner, date }) {
    return (
        <div className="landing-drops-info">
            <div>
                <span className="landing-drops-info__title">
                    Drop by <span>{owner}</span> on {date}
                </span>
                <span className="landing-drops-info__description">
                    A Rare, Matte Bleu Marine Alligator, Deep Blue Madam, Orange H Swift, Bleu Indigo & Bleu Obscur Sombrero, Craie Epsom Leather Faubourg Sellier Birkin 20 With Palladium Hardware.
                </span>
            </div>
            <div className="landing-drops-info__down">
                <div className="landing-drops-info__count">
                    <span>
                        COUNT DOWN
                    </span>
                    <Countdown date={Date.now() + 1000000000}  renderer={CountDown}/>
                </div>
                <div className="landing-drops-info__btns">
                    <Btn title="Remind Me!" className="btn__solid landing-drops-info__remind" icon={_NOTIF()} iconPosition='left' />
                    <Btn title="Upcoming Drops" className="btn__border landing-drops-info__upcoming" icon={_ARROWRIGHT()} iconPosition='right'/>
                </div>
            </div>
        </div>
    )
}
