import Header from './landingTopHeader'
import Contain from './landingTopContain'

export default function LandingTop() {
    return (
        <div className="container landing-top">
            <Header />
            <Contain />
        </div>
    )
}
