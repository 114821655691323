import Header from './landingCategoryHeader'
import Nav from './landingCategoryNav'
import Contain from './landingCategoryContain'

export default function LandingCategory() {
    return (
        <>
            <div className="container">
                <Header />
                <Nav />
            </div>
            <Contain />
        </>
    )
}
