import React from 'react'
import { _FIRST , _FIRSTHOME } from '../../assets/icons/icons'

export default function LandingTopCard({ items , className = ''  , home = false }) {
    return (
        <>
            {items.map(({ name, id, price, convert, img }, ind) => (
                <div key={ind} className={`landing-top-card ${ind === 0 ? 'first' : " "} ${className}`} >
                    <span className="landing-top-card__count">
                        {ind === 0 && !home ? _FIRST('dark') : ind === 0 && home ? _FIRSTHOME() : `${ind + 1}.`}
                    </span>
                    <img className="landing-top-card__img" src={img} alt="" />
                    <div className="landing-top-card__info" >
                        <div>
                            <span className="landing-top-card__name">{name}</span>
                            <span className="landing-top-card__price" >{price}<span>ETH</span></span>
                        </div>
                        <div>
                            <span className="landing-top-card__id" >{id}</span>
                            <span className="landing-top-card__convert" >${convert}</span>
                        </div>
                    </div>
                </div>
            ))}

        </>
    )
}
