import Select from '../../elements/landingSelect'

export default function landingCategoryHeader() {
    return (
        <div className="landing-category-header">
            <span className="landing-category-header__title">
                CATEGORIES
            </span>
            <Select/>
        </div>
    )
}
