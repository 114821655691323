

export default function landingCardProfile({img , id}) {
    return (
        <div className="blur-bg landing-card-profile">
          <img className="landing-card-profile__img" src={img} alt="" /> 
          <span className="landing-card-profile__id">
            {id}
          </span>
        </div>
    )
}
