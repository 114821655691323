import React from 'react';
import LandingMainTitles from './landingMainTitles'
import LandingMainBtns from './landingMainBtns'
import LandingMainStatus from './landingMainStatus'
import LandingCard from '../../elements/landingCard'
import useResponsive from '../../../hooks/useResponsive'
import LandingEffect from './landingCardEffect';


const LandingMain = () => {
    const [DESKTOP] = useResponsive()
    return (
        <div className="container landing-main">
            <div className="landing-main-left">
                <LandingMainTitles />
                <LandingMainBtns />
                <LandingMainStatus />
            </div>
            <div className="landing-main-right">
                {!DESKTOP && <span className='landing-main-right__title'>Best Seller</span>}
                {DESKTOP && <LandingEffect/>}
                <LandingCard name='Psycho Astronaut' likes='25' id='@Emilie' price='1.45' convert='3,840.25' img='./images/landing-card.png' effect={true} />
            </div>
        </div>
    )
};

export default LandingMain