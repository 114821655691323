
export default function landingCardPrice({ price, convert, type }) {
    return (
        <div className={`landing-card-price ${type === 'fixed' ? 'fixed' : ''}`}>
            <span className="landing-card-price__title">
                {type === 'fixed' ? 'fixed price' : 'Current Bid'}
            </span>
            {type !== "fixed" ?
                <>
                    <span className="landing-card-price__price">
                        {price} <span>ETH</span>
                    </span>
                    <span className="landing-card-price__convert">
                        ${convert}
                    </span>
                </>
                :
                <div className="landing-card-price__fixed">
                    <span className="landing-card-price__price">
                        {price} <span>ETH</span>
                    </span>
                    <span className="landing-card-price__convert">
                        ${convert}
                    </span>
                </div>}
        </div>
    )
}
