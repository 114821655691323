 const landingRoutes = [
     {
         title: 'Explore',
         path: '/'
     },
     {
        title: 'Marketplace',
        path: '/marketplace'
    },
    {
        title: 'My items',
        path: '/items'
    },
    {
        title: 'Following',
        path: '/following'
    },
    {
        title: 'Community',
        path: '/community'
    },
    {
        title: 'How it works',
        path: '/home'
    }
 ];

 export const nyftis = [
     {
        title: 'Explore',
        path: '/'   
     },
     {
        title: 'How it works',
        path: '/how'   
     },
     {
        title: 'Support',
        path: '/Support'   
     },
     {
        title: 'Become a partner',
        path: '/become'   
     }
 ]

export const community = [
    {
        title: 'Token',
        path: '/token'   
    },
    {
        title: 'Discussion',
        path: '/discussion'   
    },
    {
        title: 'Voting',
        path: '/voting'   
    },
    {
        title: 'Suggest feature',
        path: '/suggest'   
    },
]

 export default landingRoutes