export default function CardCountDown({ days, hours, minutes, seconds }) {
    return (
        <div className="card-count-down">
            <div className="card-count-down__part">
                <span className="card-count-down__count">
                {hours}
                </span>
                <span className="card-count-down__title">
                    H
                </span>
            </div>
            <div className="card-count-down__part">
                <span className="card-count-down__count">
                {minutes}
                </span>
                <span className="card-count-down__title">
                    M
                </span>
            </div>
            <div className="card-count-down__part">
                <span className="card-count-down__count">
                {seconds}
                </span>
                <span className="card-count-down__title">
                    S
                </span>
            </div>
        </div>
    )
}
